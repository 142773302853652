<template>
  <div class="main">
    <div class="row px-4 py-4 mb-3">
      <div
        v-for="year in years"
        :key="year"
        class="contentContainer col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-4"
      >
        <div class="iconMainContainer d-flex flex-column">
          <svg
            class="bi"
            id="addBranch"
            fill="currentColor"
            style="cursor:pointer"
            @click="navigateFolder(year)"
          >
            <use xlink:href="/img/icons/bootstrap-icons.svg#folder-fill" />
          </svg>
          <p
            class="text-center px-2"
            style="cursor:pointer"
            @click="navigateFolder(year)"
          >
            {{ year }}
          </p>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<script>
import Legal from "@/components/layout/Legal";
import Storage from "@/modules/storage";
import { prettyDate } from "@/modules/tools";
import axios from "axios";

export default {
  components: { Legal },
  data() {
    return {
      years: []
    };
  },
  beforeCreate() {
    const store = new Storage();
    store.getData("branch").then(branchData => {
      branchData.forEach(branch => {
        if (this.$route.params._id === branch._id) {
          store.getData("city", branch.city).then(cityData => {
            localStorage.setItem(
              "currentLocation",
              "Documentos / Archivo Inactivo / " + cityData.name
            );
          });
        }
      });
    });
    const URL =
      store.baseURL +
      "/optima/fileInactive?where=extension=FOLDER,branch=" +
      this.$route.params._id;
    axios
      .get(URL, {
        withCredentials: true
      })
      .then(response => {
        this.putFolder(response.data.body);
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  methods: {
    async putFolder(folders) {
      const currenYear = new Date().getFullYear().toString();
      folders.forEach(async folder => {
        folder.lastUpdate = await prettyDate(folder.lastUpdate);
        folder.year = folder.lastUpdate.slice(-4);
        if (folder.year != currenYear && !this.years.includes(folder.year)) {
          this.years.push(folder.year);
          this.years.sort();
        }
      });
    },
    navigateFolder(year) {
      location.href =
        "/document/inactive/" + this.$route.params._id + "/" + year;
    }
  },
  name: "Document Inactive Branch"
};
</script>
